import * as React from 'react';
import {Alert} from 'react-bootstrap';
import {faCheckCircle, faExclamationCircle, faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {forwardRef} from 'react';
import {Variant} from 'react-bootstrap/types';

export interface AlertsProps {
    alertLinkHidden?: boolean;
    alertLinkHref?: string;
    alertLinkText?: string;
    className?: string;
    CssStyles?: React.CSSProperties;
    e2e?: string;
    title?: string | React.JSX.Element;
    message: string | React.JSX.Element;
    message2?: string | React.JSX.Element;
    multiLine?: boolean;
    onClickAlertLink?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    variant: Variant;
}

export const Alerts = forwardRef<HTMLElement, AlertsProps>(
    (
        {
            alertLinkHidden,
            alertLinkHref,
            alertLinkText,
            className,
            CssStyles,
            e2e,
            title,
            message,
            message2,
            multiLine,
            onClickAlertLink,
            variant,
        }: AlertsProps,
        ref,
    ) => {
        /**
         * Returns FontAwesomeIcon based on iconVariant
         * @param iconVariant
         */
        const getIconType = (iconVariant: Variant): JSX.Element => {
            switch (iconVariant) {
                case 'danger':
                    return (
                        <FontAwesomeIcon
                            className="mr-3"
                            icon={faExclamationCircle}
                        />
                    );
                case 'warning':
                    return (
                        <FontAwesomeIcon
                            className="mr-3"
                            icon={faExclamationTriangle}
                        />
                    );
                case 'success':
                    return (
                        <FontAwesomeIcon
                            className="mr-3"
                            icon={faCheckCircle}
                        />
                    );
                default:
                    return;
            }
        };

        /**
         * Returns calculated className
         */
        const formatClassStyles = (): string => '' + (className ? className + ' ' : '') + (multiLine ? 'alert-multiline' : '');

        /**
         * Template
         */
        return message ? (
            <Alert
                className={formatClassStyles()}
                data-e2e={e2e}
                ref={ref}
                style={CssStyles}
                variant={variant}
            >
                {getIconType(variant)}
                <div className="text-wrap">
                    {title && <div className="font-weight-bold">{title}</div>}
                    {message}{' '}
                    {!alertLinkHidden && (alertLinkHref || onClickAlertLink) && (
                        <a
                            className="alert-link tw-cursor-pointer"
                            href={alertLinkHref}
                            onClick={(e) => {
                                if (onClickAlertLink) {
                                    e.preventDefault();
                                    onClickAlertLink(e);
                                }
                            }}
                        >
                            {alertLinkText}
                        </a>
                    )}
                    {message2 && <div>{message2}</div>}
                </div>
            </Alert>
        ) : null;
    },
);
